import React, { useState } from 'react';
import { signIn } from 'next-auth/react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import Notification from '@components/Notification';
import { useRouter } from 'next/router';
import EyeSvg from 'icons/eye.svg';
import EyeOffSvg from 'icons/eye-off.svg';
import MailIcon from 'icons/mail.svg';
import LockOpened from 'icons/lock-opened.svg';
import Logo from '../../../public/logo-mia.svg';
import Background from '../../../public/login/login.jpg';

function SignIn() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const router = useRouter();
  const redirectURL = router.query.callbackUrl as string;

  return (
    <>
      <Head>
        <title>Login | MIA</title>
      </Head>
      <div className='login-page'>
        <section className='login__container'>
          <div className='login__image-container'>
            <Image
              src={Background}
              alt='login-image'
              className='login__image'
              width={2560}
              height={1440}
              priority
            />
          </div>

          <div className='login__login-container'>
            <div className='login__login-wrapper'>
              <div className='login__logo-container'>
                <Logo className='login__logo' />
              </div>
              <h1 className='login__headline'>Mitarbeiter Informationssystem Ammerland</h1>
              {loginError ? (
                <Notification
                  notificationItem={{
                    type: 'error',
                    text: 'Bitte gebe einen gültigen Benutzernamen und ein gültiges Passwort ein',
                  }}
                />
              ) : null}
              <form
                className='login__form'
                method='post'
                onSubmit={async event => {
                  event.preventDefault();
                  const username = event?.currentTarget.username.value;
                  const password = event?.currentTarget.password.value;
                  if (username && password) {
                    const res = await signIn('credentials', {
                      username,
                      password,
                      redirect: false,
                      callbackUrl: redirectURL || '/',
                    });
                    if (res?.ok && res.url) {
                      setLoginError(false);
                      window.location.href = res.url;
                    } else {
                      setLoginError(true);
                    }
                  }
                }}
              >
                <div className='login__form-inputs'>
                  <label
                    htmlFor='username'
                    className='login__form-label top'
                    aria-label='Username-Input'
                  >
                    <input
                      id='username'
                      name='username'
                      type='text'
                      className='login__form-input'
                      placeholder='Benutzername'
                      required
                    />
                  </label>
                  <label
                    htmlFor='pw'
                    className='login__form-label bottom'
                    aria-label='Password-Input'
                  >
                    <input
                      id='pw'
                      name='password'
                      type={passwordShown ? 'text' : 'password'}
                      className='login__form-input'
                      placeholder='Passwort'
                      required
                    />
                    <button
                      type='button'
                      className='login__form-input-show'
                      onClick={() => {
                        setPasswordShown(!passwordShown);
                      }}
                    >
                      {passwordShown ? <EyeOffSvg /> : <EyeSvg />}
                    </button>
                  </label>
                </div>
                <div className='login__button-container'>
                  <button className='button--primary login__submit-button' type='submit'>
                    anmelden
                    <LockOpened className='login__submit-button-svg' />
                  </button>
                </div>
                <Link
                  href='/password-reset/request-reset'
                  passHref
                  className='login__form-password-reset'
                >
                  Passwort zurücksetzen
                </Link>
              </form>
              <div className='login__login-info'>
                Neu hier?
                <br />
                Für die erste Anmeldung bei MIA wendet euch bitte an die Personalabteilung und
                fordert euren Zugang über
                <a
                  className='login__mail-link'
                  href='mailto:mia@molkerei-ammerland.de?subject=Anfrage MIA Zugang&body=Vorname: %0D%0ANachname: %0D%0APersonalnummer: %0D%0AE-Mail: '
                >
                  mia@molkerei-ammerland.de <MailIcon className='login__mail-svg' />
                </a>
                an. Bitte gebt dabei euren Vor- und Nachnamen, eure E-Mail-Adresse und eure
                Personalnummer an.
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default SignIn;
